import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import TitleHeading from '../../components/TitleHeading';
import Button from '../../components/Button';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

const OurPlatform = (props) => {
  const backgroundImage = props.data.background.childImageSharp.fluid;
  const phone = props.data.phone.childImageSharp.fluid;
  return (
    <BackgroundImage
      id="section-platform"
      Tag="section"
      className="section-our-platform w-100 d-flex align-items-center"
      fluid={backgroundImage}
    >
      <div className="container d-flex justify-content-between my-4">
        <div className="section-our-platform-text d-flex flex-column align-self-center text-justify">
          <TitleHeading heading="No Touch Access" />
          <p>No need to install apps, just scan QR on the water station, choose the size and temperature of water that you need, and you can get your water without direct touch.</p>
          <p>By filling your tumbler through the IZIFILL water station, you can track how much bottle you saved to save our planet from plastic pollution.</p>
          <Button text="Refill Now" className="text-right" buttonClassName="button-primary" link="refill" />
        </div>
        <Img className="section-our-platform-img align-self-center" fluid={phone}/>
      </div>
    </BackgroundImage>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "background_platform.jpg" }) {
          childImageSharp {
            fluid (quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        phone: file(relativePath: { eq: "phone_thumbnail.png" }) {
          childImageSharp {
            fluid (quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
  `}
    render={(data) => <OurPlatform data={data} />}
  />
);
